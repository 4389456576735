import './App.css';
import { Route, Routes, BrowserRouter } from "react-router-dom";

import UploadFiles from './pages/uploads';
import Login from './pages/login';



const users = [
  {name:'mariana.marketing', password:'hud(*@YN*hd28(HN%RB5'},
  {name:'lunara.marketing', password:'gfb&R%brn7890YT&*y'},
]



function App() {

  

  const isLogged = ()=>{
    const userLogged = localStorage.getItem('islogged')?localStorage.getItem('islogged'):''
    if(userLogged){
      const userData = userLogged.split('||')
      if (users.some(single=>single.name===userData[0])) {
        const loggedDate = new Date(userData[1])
        const expiresAt = loggedDate.getTime() + (1000 * 60 * 60) //expires in one hour
        const curreTime = (new Date()).getTime()
        if (curreTime<expiresAt) {
          return true
        }
        else{
          localStorage.removeItem('islogged')
        }
      }
    }
    return false
  }




  return (
 
    <div style={{}}>
    <BrowserRouter>
    
    <Routes>
      
      <Route element={<UploadFiles/>} exact path='/'/>
      {/* <Route Component = { Uploads }  path="/" exact /> */}
    </Routes>
    
    </BrowserRouter>

      <div style={isLogged()?{display:'none',}:{display:'flex', position:'absolute', width:'100%', height:'100%', backgroundColor:'#000c7c', zIndex:'99999999', left:'0', top:'0',  }}>
        <Login users={users}/>
      </div>
    </div>



);
}

export default App;
