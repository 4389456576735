import React, { useEffect, useState } from "react";
import axios from 'axios';

import './upload.css'

import { API_BASE } from "../const/const";

import imagIcon from "../assets/icons/fileIcon.svg"
import closeGrey from "../assets/icons/close-grey.svg"
import closeRed from "../assets/icons/close-red.svg"
import { Hidden } from "@mui/material";


// import AddDocs from "./AddDocs";

//      [access_url param="academico"]

//DON'T TOUCH THIS CONST!!! IT CAN AFFECT THE DATABASE
const auxToShow = [
    {number:0, name:'Acadêmico', path:'academico'},
    {number:1, name:'Calendários acadêmicos', path:'calendario-academico'},
    {number:2, name:'Calendários de eventos', path:'calendario-eventos'},
    {number:3, name:'Editais de Concessão de Bolsas', path:'editais-bolsas'},
    {number:4, name:'Editais de Monitoria', path:'editais-monitoria'},
    {number:5, name:'Editais de Nivelamento', path:'editais-nivelamento'},
    {number:6, name:'Editais de Processo Seletivo', path:'editais-processo-seletivo'},
    {number:7, name:'Editais de Renovação de Matricula', path:'editais-renovacao-matricula'},
    {number:8, name:'Editais de Vestibular', path:'editais-vestibular'},
    {number:9, name:'Editais de Nupex', path:'editais-nupex'},
    {number:10, name:'FIES', path:'fies'},
    {number:11, name:'PROUNI', path:'prouni'},
    {number:12, name:'CPA', path:'cpa'},
    {number:13, name:'NADD', path:'nadd'},
    {number:14, name:'Manuais e Regulamentos', path:'manuais-regulamentos'},
    {number:15, name:'Resoluções', path:'resolucoes'},
    {number:16, name:'Financeiro', path:'financeiro'},
    {number:17, name:'Institucionais', path:'institucionais'},

]




// const FilesToShow = [
//     {ID:1, name:'Arquivo 1', imgUrl:imagIcon},
//     {ID:1, name:'Arquivo 2', imgUrl:imagIcon},
//     {ID:1, name:'Arquivo 3', imgUrl:imagIcon},
//     {ID:1, name:'Arquivo 4', imgUrl:imagIcon},
//     {ID:1, name:'Arquivo 5', imgUrl:imagIcon},
//     {ID:1, name:'Arquivo 6', imgUrl:imagIcon},
//     {ID:1, name:'Arquivo 7', imgUrl:imagIcon},
//     {ID:1, name:'Arquivo 8', imgUrl:imagIcon},
//     {ID:1, name:'Arquivo 9', imgUrl:imagIcon},
//     {ID:1, name:'Arquivo 10', imgUrl:imagIcon},
//     {ID:1, name:'Arquivo 11', imgUrl:imagIcon},
//     {ID:1, name:'Arquivo 12', imgUrl:imagIcon},
//     {ID:1, name:'Arquivo 13', imgUrl:imagIcon},
//     {ID:1, name:'Arquivo 14', imgUrl:imagIcon},
//     {ID:1, name:'Arquivo 15', imgUrl:imagIcon},
//     {ID:1, name:'Arquivo 16', imgUrl:imagIcon},
//     {ID:1, name:'Arquivo 17', imgUrl:imagIcon},
//     {ID:1, name:'Arquivo 18', imgUrl:imagIcon},
//     {ID:2, name:'Arquivo 19', imgUrl:imagIcon},
//     {ID:3, name:'Arquivo 20', imgUrl:imagIcon},
//     {ID:4, name:'Arquivo 21', imgUrl:imagIcon},
//     {ID:5, name:'Arquivo 22', imgUrl:imagIcon},
// ]








export default function UploadFiles (){

    const [selected,setselected]=useState(-1)
    const [selectedINFO,setselectedINFO]=useState({})
    const [has_attachments,setHas_attachments]=useState(false)
    const [ds_comments,setds_comments]=useState(null)
    const [progress,setProgress]=useState(null)
    const [loading, setloading]=useState(false)

    const [selectedFiles, setSelectedFiles] = useState([]);

    const [filesToShow, setFilesToShow] = useState([]);


    const handleFileChange = (event) => {
        setSelectedFiles([...event.target.files]);
    };

      
    const handleFileUpload = async (e) =>{ 
        setloading(true)
        e.preventDefault()

        if (selectedFiles.length === 0) {
          alert('Please select a file first.');
          setloading(false)
          return;
        }
        const urlToSend = `${API_BASE}uploading/uploadingFiles`;
        const path =  selectedINFO.path;
        var formData = new FormData();
        formData.append('pathtosave', path); 
        formData.append('sentFor', localStorage.getItem('islogged')); 
        for (let i = 0; i < selectedFiles.length; i++) {
            const fileName = encodeURIComponent(selectedFiles[i].name);
            console.log(`fileName = ${fileName}`);
            formData.append(`files`, selectedFiles[i], fileName);
        }
        await axios.post(urlToSend, formData)
          .then((res) => {
            setloading(false)
            alert('Upload com SUCESSO. ');

            console.log(res?.data?.files);
            setSelectedFiles([])
            setFilesToShow(res?.data?.files);

            
          })
          .catch((error) => {
              setloading(false)
            console.log('ERRO:', error);

          });
      };


      const handleFileDelete = (fileIndex)=>{
        let aux = [...selectedFiles]

        // alert(JSON.stringify(aux))
        aux = aux.filter((_, index) => index !== fileIndex)

        setSelectedFiles(aux)
      }

      const handleDeleteFileFromServer = (fileName , docType) => {


        axios.post(`${API_BASE}uploading/deleteFile` , { fileName:fileName, docType: docType, } )
        .then((res) => {
            console.log('ok');
            console.log(res?.data?.message)

            setFilesToShow(res?.data?.files)

        })
        .catch ((error)=>{
            console.log('ERRO:', error);

        })

      }



      useEffect(()=>{

            
        if(selectedINFO?.path) {

       

        axios.get(`${API_BASE}get/files/${selectedINFO?.path}`)
          .then((res) => {

            // console.log('deu certo');
            // console.log(res);
            // console.log(res?.data?.files);
            setFilesToShow(res?.data?.files);
            
 
        })

          .catch((error) => {
            console.log('ERRO:', error);

          });

 }

      },[selectedINFO])




 


    return(
        <div style={{display:'flex', gap:'10px', fontFamily:'poppins', fontSize:'12pt', padding:'10px', }}>

            {loading && <div style={{display:'flex', position:'absolute', width:'100%', height:'100%', backgroundColor:'rgba(0, 12, 124,0.5)', zIndex:'99999999', left:'0', top:'0',  color:'white', alignContent:'center', justifyContent:'center', alignItems:'center', justifyItems:'center', fontSize:'30pt', textAlign:'center', fontWeight:'1000'}}>Aguarde,<br/>carregando . . .</div>}

            <div style={{display:'flex', flexDirection:'column', gap:'10px', maxWidth:'300px',   }}>
            {auxToShow.map((single,index)=>{
                return <div onClick={()=>{setselected(index);setselectedINFO(single)}} style={{display:'flex', backgroundColor:'#000c7c', color:'white', justifyContent:'center', padding:'5px', cursor:'pointer', borderRadius:'5px' }}>{single.name}</div>
            })}
            </div>

            <div style={{display:'flex',flexDirection:'column', backgroundColor:'rgba(0,0,0,0.1)', width:'70%', fontFamily:'poppins', fontSize:'12pt', fontWeight:'bold', padding:'20px'}}>


                {selected >-1 &&
                <>
                    <div style={{display:'flex', justifyContent:'center',alignContent:'center', width:'100%'}}>{((auxToShow[selected]).name)}</div> 
                    <div style={{width:'100%', height:'1px', backgroundColor:'#000c7c', margin:'10px auto 10px auto'}}/>

                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center',alignContent:'center', width:'100%', height:'100%',maxHeight:'700px', gap:'10px'}}>

                        <div style={{display:'flex',  justifyContent:'center',alignContent:'center', width:'100%', gap:'10px'}}>
                            <label className="custom-file-upload" style={{display:'flex',  justifyContent:'center',alignContent:'center', width:'150px', gap:'10px' }}>
                                <input type="file" multiple onChange={handleFileChange} />
                                Clique para adicionar
                            </label>
                            <button  style={{display:'flex',  justifyContent:'center',alignContent:'center', alignItems:'center', padding:'5px 10px 5px 10px', width:'auto',  cursor:'pointer'}} onClick={handleFileUpload}>Upload</button>
                        </div>



                        <div style={{display:'flex',gap:'10px', alignItems: 'center', justifyContent: 'center', margin: 'auto', padding: '10px 10px 10px 10px',maxHeight: '80%' , width: '100%', overflow:'auto', flexWrap:'wrap'  }}>


                            {
                                filesToShow?.map((file,index)=>{
                                    return (

                                        <div style={{display:'flex', flexDirection: 'column',  alignItems: 'center', width:'20%', height:'200px', overflow:'hidden', padding:'10px', textAlign:'center', }}> 
                                            <img src={imagIcon} width='50px' />
                                         <img onClick={ ()=>{handleDeleteFileFromServer(file,selectedINFO?.path)}  }                                              src={closeRed} width='20px'             style={ {position:"relative", bottom:"60px",  right:'-20px', cursor:"pointer", zIndex:'9999999'        }                                }                        /> 
                                            <h6 style={{fontSize:'12pt'}}>{file}</h6> 
                                        </div>
                                    )
                                })
                            }


                                            {/* <div style={{display:'flex', flexDirection: 'column', alignItems: 'center'}}> <img src=    width='50px'/> Arquivo 1 </div> 
                                            <div style={{display:'flex', flexDirection: 'column', alignItems: 'center'}}> <img src=    width='50px'/> Arquivo 2 </div>
                                            <div style={{display:'flex', flexDirection: 'column', alignItems: 'center'}}> <img src=    width='50px'/> Arquivo 3 </div>
                                            <div style={{display:'flex', flexDirection: 'column', alignItems: 'center'}}> <img src=    width='50px'/> Arquivo 4 </div>
                                            <div style={{display:'flex', flexDirection: 'column', alignItems: 'center'}}> <img src=    width='50px'/> Arquivo 5 </div> */}
                        </div>


                        {selectedFiles.length > 0 && (
                            <div>
                            <h2>Arquivos selecionados:</h2>
                            <div style={{display:'flex', flexDirection:'column', width:'100%', height:'400px', gap:'30px',  overflow:'auto', }} >
                                {selectedFiles.map((file, index) => (
                                <div style={{display:'flex',  width:'100%', height:'50px', gap:'30px', alignItems:'center', }}>
                                        <div style={{width:'300px', overflow:'hidden'}}>
                                            <h5 >
                                                {file?.name}
                                            </h5>
                                        </div>
                                        <div style={{width:'1px', height:'100%', backgroundColor:'#000c7c'}}/>
                                        <div style={{width:'100px'}}>
                                            {file?.type?.startsWith('image/') && (
                                                <img
                                                src={URL.createObjectURL(file)}
                                                alt={`Preview for ${file?.name}`}
                                                style={{width:'100%', height:'auto', maxWidth: '100px', maxHeight: '100px', marginLeft: '10px' }}
                                                />
                                                )}
                                        </div>
                                        <div style={{width:'1px', height:'100%', backgroundColor:'#000c7c'}}/>
                                    <button onClick={()=>{handleFileDelete(index)}} >Excluir</button>
                                </div>
                                ))}
                            </div>
                            </div>
                        )}

                    </div>

                </>
                }
                {selected <0 &&
                <>
                    <div style={{display:'flex', justifyContent:'center',alignContent:'center',alignItems:'center', width:'100%', height:'100%',fontSize:'20pt', }}>Escolha qual tipo de arquivo deseja fazer upload</div> 
                </>
                }

            </div>




        </div>
    )
} 