import React, { useEffect, useState } from "react";



export default (props) =>{

    const [name, setname]=useState('')
    const [password, setpassword]=useState('')

    const [ok,setok]=useState(false)

    useEffect(()=>{
        if (name && password) {
            return setok(true)
        }
        return setok(false)
    },[name,password])
    
    const handleLogin = ()=>{

        if (props.users.some(elem=>elem.name===name && elem.password===password)) {
            localStorage.setItem('islogged',`${name}||${new Date()}`)
            window.location.reload()
            return 
        }
        return

    }


    return (
        <div style={{display:'flex', flexDirection:'column', width:'30%', height:'50%', backgroundColor:'white', borderRadius:'10px', margin:'auto', fontFamily:'poppins', fontSize:'15pt', textAlign:'center', alignItems:'center',}}>
            <div style={{display:'flex', flexDirection:'column', width:'100%',  margin:'auto', gap:'20px' }}>
                <div style={{fontWeight:'bold', marginBottom:'50px'}}>Insira seus dados para acessar:</div>
                <div style={{display:'flex', flexDirection:'column', width:'100%', justifyContent:'center', justifyItems:'center', gap:'10px'}}>
                    <div style={{display:'flex', flexDirection:'column', width:'50%', margin:'10px auto 10px auto', gap:'10px'}}>
                        Usuário<input type="text"       style={{borderRadius:'10px', height:'30px', }} value={name} onChange={(e)=>{setname(e.target.value)}}/>
                        Senha<input type="password"     style={{borderRadius:'10px', height:'30px', }} value={password} onChange={(e)=>{setpassword(e.target.value)}}/>
                    </div>
                    <div style={
                        ok?
                        {cursor:'pointer', display:'flex', padding:'10px',borderRadius:'10px', width:'100px', height:'auto', backgroundColor:'blueviolet', justifyContent:'center',justifyItems:'center', alignItems:'center', alignContent:'center',color:'white', fontSize:'12pt', fontWeight:'bold', margin:'10px auto 10px auto'}
                        :
                        {display:'flex', padding:'10px',borderRadius:'10px', width:'100px', height:'auto', backgroundColor:'grey', justifyContent:'center',justifyItems:'center', alignItems:'center', alignContent:'center',color:'white', fontSize:'12pt', fontWeight:'bold', margin:'10px auto 10px auto'}
                    }
                    onClick={()=>{handleLogin()}}
                    >acessar</div>
                </div>
            </div>
        </div>
    )

}